


















































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import {
  BaseButton,
  TypeBody,
  TypeDisplay,
  BaseIcon,
  BaseBanner,
} from '@bambeehr/pollen';
import LayoutApplicationStyle from '@/modules/SelfServiceRegistration/components/LayoutApplicationStyle.vue';
import getStripe from '@/utils/getStripe';
import api from '@/services/api';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import useSelfService from '@/modules/SelfServiceRegistration/hooks/useSelfService';
import { updatePlan } from '@/services/Payment';
import usePricePlans, { PlanProductKey } from '@/hooks/usePricePlans';
import Analytics from '@/services/Analytics';
import SelfServicePages, {
  LocalStorageKeys,
  SelfServicePageSlugs,
} from '../../constants/SelfServicePages';
import BillingPeriod from '@/constants/Billing';
import { goToPage } from '../../utils/selfServiceRouting';
import GlowCard from '@/modules/SelfServiceRegistration/components/GlowCard.vue';
import useTrials from '@/hooks/useTrials';
import { getFromLocalStorage } from '@/utils/localStorage';

enum TrackEvents {
  ACH_ADDED = `ach_added`,
  ACH_ERROR = `ach_error`,
  CONTINUE_MONTHLY = `ach_confirmation_continue_monthly`,
  CONTINUE_YEARLY_T3_PLUS_MONTHLY = `ach_confirmation_continue_yearly_t3_plus_monthly`,
  CONTINUE_YEARLY_T3_PLUS = `ach_confirmation_continue_yearly_t3_plus`,
}

const PaymentElementTypes = {
  US_BANK_ACCOUNT: 'us_bank_account',
};

function adjustMaxHeight() {
  const intervalDuration = 200; // 0.2 seconds
  const maxRuntime = 12000; // 12 seconds
  const endTime = Date.now() + maxRuntime;

  const intervalId = setInterval(() => {
    const iframe = document.querySelector(
      '.payment-element iframe'
    ) as HTMLElement;

    if (iframe) {
      // remove px from string
      const actualHeight = Number(iframe.style.height.replace('px', ''));
      iframe.style.maxHeight = `${actualHeight - 40}px`;
    }

    if (Date.now() >= endTime) {
      clearInterval(intervalId); // Stop interval after the full runtime
    }
  }, intervalDuration);
}

export default defineComponent({
  name: 'ACHConfirmationPage',
  components: {
    LayoutApplicationStyle,
    BaseButton,
    TypeBody,
    TypeDisplay,
    BaseIcon,
    BaseBanner,
    GlowCard,
  },
  setup(_, { emit }) {
    const complete = ref(false);
    const isDisabled = computed(() => !complete.value);
    const { state: registrationState, updateRegistration } = useRegistration();
    const { trialDuration } = useTrials();
    const { goToNextPage } = useSelfService();
    const { selectedPlan, setSelectedPlanById, isT3Plus } = usePricePlans();
    const isLoading = ref(false);
    const stripeLoaded = ref(false);
    const billingPlanId = getFromLocalStorage(LocalStorageKeys.BILLING_PLAN_ID);
    if (billingPlanId) {
      setSelectedPlanById(billingPlanId);
      updateRegistration({
        [RegistrationStateNames.PLAN_ID]: billingPlanId,
      });
    }

    const showCreditCard = () => {
      setTimeout(() => {
        stripeLoaded.value = true;
      }, 800);
    };

    const trackEvent = (eventName: TrackEvents, additionalData = {}) => {
      Analytics.track(eventName, {
        registrationState,
        ...additionalData,
      });
    };

    const headlineText = computed(() =>
      isT3Plus.value
        ? 'Add ACH info to save time later'
        : `Great! Let's Lock in Your Savings with ACH`
    );
    const subheadlineText = computed(() =>
      isT3Plus.value
        ? `Bambee keeps prices low by avoiding costly credit card fees. Add ACH now so your first call with Bambee can focus on your company's HR--not adding payment info.`
        : `This quick process takes less than 2 minutes, and remember— <b>you won't be charged until after your ${trialDuration.value}-day trial ends.</b>`
    );

    let stripe;
    let elements;
    let paymentElement;
    let clientSecret;

    onMounted(async () => {
      stripe = await getStripe();

      clientSecret = await api
        .post('/billing/billing/v2/setup-intent', {
          paymentMethodLayout: 'bank',
        })
        .then((res) => res.data.stripeSetupIntentClientSecret);

      const appearance = {
        /* appearance */
      };
      const options = reactive({
        defaultValues: {
          billingDetails: {
            name: registrationState[RegistrationStateNames.FULL_NAME],
            email: registrationState[RegistrationStateNames.EMAIL],
          },
        },
        paymentMethodOrder: [PaymentElementTypes.US_BANK_ACCOUNT],
        allowedPaymentMethods: [PaymentElementTypes.US_BANK_ACCOUNT],
      });

      elements = stripe.elements({
        clientSecret,
        appearance,
      });

      paymentElement = elements.create('payment', options);

      paymentElement.mount('#payment-element');

      showCreditCard();
      paymentElement.on('change', (event) => {
        trackEvent(TrackEvents.ACH_ADDED);
        complete.value = event.complete;
      });

      paymentElement.on('ready', () => {
        adjustMaxHeight();
      });
    });

    const updatePlanAndContinue = async () => {
      isLoading.value = true;
      await updatePlan({
        companyId: registrationState[RegistrationStateNames.COMPANY_ID],
        planValue: selectedPlan.value?.value,
      }).then(() => {
        updateRegistration({
          [RegistrationStateNames.PAYMENT_METHOD]: 'ACH',
          [RegistrationStateNames.BILLING_PERIOD]:
            selectedPlan.value?.billingPeriod,
          [RegistrationStateNames.PLAN_ID]: selectedPlan.value?.value,
        });

        isLoading.value = false;

        goToNextPage();
      });
    };

    const handleContinue = async () => {
      isLoading.value = true;

      const { error: submitError } = await elements.submit();
      if (submitError) {
        trackEvent(TrackEvents.ACH_ERROR, { error: submitError });
        console.error(submitError);

        return;
      }

      stripe
        .confirmSetup({
          clientSecret,
          elements,
          redirect: 'if_required',
        })
        .then(async (result) => {
          trackEvent(TrackEvents.CONTINUE_YEARLY_T3_PLUS, {
            target: selectedPlan.value?.billingPeriod,
          });
          await updatePlanAndContinue();
        })
        .catch((error) => {
          isLoading.value = false;
          console.log('error', error);
        });
    };

    const handleContinueWithCreditCard = async () => {
      if (isT3Plus.value) {
        trackEvent(TrackEvents.CONTINUE_YEARLY_T3_PLUS_MONTHLY, {
          target: BillingPeriod.MONTH,
        });

        await updatePlanAndContinue();
      } else {
        trackEvent(TrackEvents.CONTINUE_MONTHLY, {
          target: BillingPeriod.MONTH,
        });
      }

      emit('submit');
      // goToPage(SelfServicePages[monthlyConfirmationIndex], router);
    };

    const achBenefits = [
      `<b>Avoid credit card processing fees.</b>`,
      `<b>Enjoy uninterrupted service with reliable payments.</b>`,
      `<b>More Control: </b>ACH payments are directly linked to your bank account, offering a seamless secure transaction.`,
    ];

    return {
      headlineText,
      subheadlineText,
      handleContinue,
      isDisabled,
      isLoading,
      handleContinueWithCreditCard,
      stripeLoaded,
      achBenefits,
    };
  },
});
