<template>
  <AdvisorDialog class="relative">
    <div
      v-if="isFreeTrialPage"
      class="special-banner absolute text-white"
      aria-placeholder="Special Offer"
    >
      Special Offer
    </div>
    <h2 class="text-center font-bold mb-1 text-23">
      <template v-if="isFreeTrialPage">
        Try Bambee
        <span class="text-secondary">For Free</span>
      </template>
      <template v-else>{{ heading }}</template>
    </h2>
    <form @submit.prevent="handleSubmit">
      <ReasonDropdown
        :invalid="isInvalid"
        :dropdown-label="dropdownLabel"
        placeholder="Choose your reason"
        class="w-full text-center"
        :show-reset="false"
        :searchable="false"
        data-qa="bambee-interest-dropdown-select"
        input-size="normal"
      />
      <BaseButton
        :id="buttonId"
        variant="secondary"
        postIcon="arrow-right"
        size="large"
        class="my-4"
        block
        type="submit"
      >
        {{ buttonText }}
      </BaseButton>
    </form>
  </AdvisorDialog>
</template>

<script>
import { BaseButton } from '@bambeehr/pollen';
import { computed, ref, useRoute } from '@nuxtjs/composition-api';

import AdvisorDialog from '@/components/AdvisorDialog';

import useFreeTrialPromo from '@/hooks/useFreeTrialPromo';
import useRadioPromo from '@/hooks/useRadioPromo';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import selfCheckout from '@/constants/SelfCheckout';

import SelfServicePages from '@/modules/SelfServiceRegistration/constants/SelfServicePages';
import useMatchMedia from '@/hooks/useMatchMedia';
import ReasonDropdown from '@/components/ReasonDropdown';
import useSelfService from '@/modules/SelfServiceRegistration/hooks/useSelfService';
import { netlifyEnterTest } from '@/utils/netlifyTest';


export default {
  components: {
    AdvisorDialog,
    BaseButton,
    ReasonDropdown,
  },
  props: {
    // For Heap reporting
    buttonId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Let's see how we can help",
    },
    buttonTitle: {
      type: String,
      default: 'Next',
    },
    dropdownLabel: {
      type: String,
      default: 'What brought you to Bambee?',
    },
  },
  setup(props) {
    const { state, updateRegistration } = useRegistration();
    const { isRadioPage } = useRadioPromo();
    const { isFreeTrialPage } = useFreeTrialPromo();
    const route = useRoute();
    const matchMedia = useMatchMedia();
    const isLarge = computed(() => matchMedia.value.sm || matchMedia.value.xs);
    const { goToNextPage } = useSelfService();

    netlifyEnterTest();

    const isInvalid = ref(false);

    const handleSubmit = () => {
      if (!state[RegistrationStateNames.WHAT_BROUGHT_YOU_TO_BAMBEE]) {
        isInvalid.value = true;

        return;
      }
      updateRegistration({
        [RegistrationStateNames.PAGE_REGISTERED_ON]: `${window.location.origin}${route.value.path}`,
        [RegistrationStateNames.FUNNEL]: selfCheckout,
      });
      window?.analytics?.page({
        url: `${window.location.origin}/registration/${SelfServicePages[1].slug}`,
        path: `/registration/${SelfServicePages[1].slug}`,
        referrer: `${window.location.origin}${route.value.path}`,
      });
      goToNextPage();
    };

    const heading = computed(() => {
      return isRadioPage.value
        ? 'Schedule HR audit & overview'
        : props.title;
    });

    const buttonText = computed(() => {
      if (isFreeTrialPage.value) {
        return 'Get Free Trial';
      }

      return props.buttonTitle;
    });

    return {
      buttonText,
      handleSubmit,
      isFreeTrialPage,
      isInvalid,
      heading,
      isLarge,
    };
  },
};
</script>

<style scoped>
.special-banner {
  background: #ee8a2e;
  clip-path: polygon(20% 5%, 80% 5%, 100% 90%, 0% 90%);
  padding: 0.5rem 2rem;
  right: -2.35rem;
  top: 1.25rem;
  transform: rotate(45deg);
  transform-origin: center;
}
div >>> .field-label--large {
  @apply mb-8;
}
div >>> .select-input__selected {
  @apply max-h-8;
}
</style>
