



































































































import {
  computed,
  ref,
  defineComponent,
  useRouter,
} from '@nuxtjs/composition-api';
import { BaseButton, TypeBody, TypeDisplay, BaseIcon } from '@bambeehr/pollen';
import LayoutApplicationStyle from '@/modules/SelfServiceRegistration/components/LayoutApplicationStyle.vue';
import GlowCard from '@/modules/SelfServiceRegistration/components/GlowCard.vue';
import usePricePlans, { PlanProductKey } from '@/hooks/usePricePlans';
import { goToPage, goToNextPage } from '../../utils/selfServiceRouting';
import SelfServicePages, {
  LocalStorageKeys,
  SelfServicePageSlugs,
} from '../../constants/SelfServicePages';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import Analytics from '@/services/Analytics';
import useTrials from '@/hooks/useTrials';
import { getFromLocalStorage } from '@/utils/localStorage';
import { updatePlan } from '@/services/Payment';

enum TrackEvents {
  PAYMENT_METHOD_CLICK = `payment_method_option_click`,
  PAYMENT_METHOD_SELECT = `payment_method_option_select`,
}

export default defineComponent({
  name: 'MonthlyConfirmationPage',
  components: {
    LayoutApplicationStyle,
    BaseButton,
    TypeBody,
    TypeDisplay,
    BaseIcon,
    GlowCard,
  },
  setup(_, { emit }) {
    const { selectedPlan, setSelectedPlanById, isReady, isT3Plus } =
      usePricePlans();
    const { updateRegistration, state } = useRegistration();
    const router = useRouter();
    const { trialDuration } = useTrials();
    const billingPlanId = getFromLocalStorage(LocalStorageKeys.BILLING_PLAN_ID);
    if (billingPlanId) {
      setSelectedPlanById(billingPlanId);
      updateRegistration({
        [RegistrationStateNames.PLAN_ID]: billingPlanId,
      });
    }

    const trackEvent = (eventName: TrackEvents, additionalData = {}) => {
      Analytics.track(eventName, {
        state,
        ...additionalData,
      });
    };
    const planColor = computed(() =>
      selectedPlan.value?.productKey === PlanProductKey.DEDICATED
        ? 'dedicated'
        : selectedPlan.value?.productKey.toLowerCase()
    );
    const isLite = computed(
      () => selectedPlan.value?.productKey === PlanProductKey.LITE
    );

    const headlineText = computed(() => 'Month-to-Month with ACH – No Fees');
    const subheadlineText = computed(
      () =>
        `Credit cards have a higher failure rate and incur additional processing fees. Switching to ACH helps us avoid these fees, and we pass the savings directly to you. <b>You won't be charged until after your ${trialDuration.value}-day trial ends.</b>`
    );

    const paymentOptions = ref([
      {
        key: 'ACH',
        label: `Switch to ACH and avoid credit card processing fees`,
        // '<div class="flex flex-row">Month-to-Month with ACH - No Fees <span class="flex items-center self-center ml-2 p-1 bg-[#F0CDF4] text-[#4e1976] rounded-md font-medium text-11">Most Popular</span></div>',
        surcharge: 0,
        surchargeText: '',
        description:
          'Quick, secure setup with instant savings. Most customers prefer this option.',
        expandableList: [
          `<b>Avoid credit card processing fees.</b>`,
          `<b>Enjoy uninterrupted service with reliable payments.</b>`,
          `<b>More Control: </b>ACH payments are directly linked to your bank account, offering a seamless secure transaction.`,
        ],
      },
      {
        key: 'CREDIT',
        label: 'Continue with Credit Card',
        surcharge: 2,
        surchargeText: isLite.value ? '' : '+ $2 surcharge',
        description:
          'Continue with card payments. Additional processing fee applies.',
        expandableList: [],
      },
    ]);

    const selectedOption = ref();

    const isLoading = ref(false);

    const updatePlanMethod = async () => {
      isLoading.value = true;

      await updatePlan({
        companyId: state[RegistrationStateNames.COMPANY_ID],
        planValue: selectedPlan.value?.value,
      }).then(() => {
        updateRegistration({
          [RegistrationStateNames.PAYMENT_METHOD]: 'ACH',
          [RegistrationStateNames.BILLING_PERIOD]:
            selectedPlan.value?.billingPeriod,
          [RegistrationStateNames.PLAN_ID]: selectedPlan.value?.value,
        });
        isLoading.value = false;
      });
    };

    const selectOption = (optionKey: string) => {
      trackEvent(TrackEvents.PAYMENT_METHOD_CLICK, {
        selectedOption: optionKey,
      });
      selectedOption.value = optionKey;
    };
    const handleContinue = async () => {
      const ACHPage = SelfServicePages.find(
        (page) => page.slug === SelfServicePageSlugs.ACH_CONFIRMATION
      );

      const PayrollSelectionPage = SelfServicePages.find(
        (page) => page.slug === SelfServicePageSlugs.PAYROLL_SELECTION
      );

      const selectedPageSlug =
        selectedOption.value === 'ACH'
          ? ACHPage?.slug
          : PayrollSelectionPage?.slug;

      const pageIndex = SelfServicePages.findIndex(
        (page) => page.slug === selectedPageSlug
      );

      trackEvent(TrackEvents.PAYMENT_METHOD_SELECT, {
        selectedOption: selectedOption.value,
      });

      goToPage(SelfServicePages[pageIndex], router);
    };

    return {
      headlineText,
      subheadlineText,
      paymentOptions,
      selectedOption,
      selectOption,
      handleContinue,
      selectedPlan,
      isReady,
      planColor,
      isLoading,
    };
  },
});
