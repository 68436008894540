





































































































































import { computed, ref, useRouter, watch, Ref } from '@nuxtjs/composition-api';
import {
  BaseButton,
  TypeBody,
  TypeDisplay,
  BaseIcon,
  ModalDialog,
} from '@bambeehr/pollen';
import useRegistration, {
  RegistrationStateNames,
} from '@/modules/SelfServiceRegistration/hooks/useRegistration';
import LayoutApplicationStyle from '@/modules/SelfServiceRegistration/components/LayoutApplicationStyle.vue';
import usePricePlans from '@/hooks/usePricePlans';
import Analytics from '@/services/Analytics';
import PricePlanSelector from '@/modules/SelfServiceRegistration/components/PricePlanSelector/PricePlanSelector.vue';
import { BillingPeriod } from '@/constants/Billing';
import OptionsShoppingCart from '@/modules/SelfServiceRegistration/components/ShoppingCart/OptionsShoppingCart.vue';
import { goToPage } from '../../utils/selfServiceRouting';
import SelfServicePages, {
  LocalStorageKeys,
  SelfServicePageSlugs,
} from '../../constants/SelfServicePages';
import currency from '@bambeehr/currency';
import useTrials from '@/hooks/useTrials';
import { saveInLocalStorage } from '@/utils/localStorage';
import { updatePlan } from '@/services/Payment';

enum TrackEvents {
  BILLING_PERIOD_CLICK = `billing_period_toggle_click`,
  LEARN_MORE_CLICK = `billing_learn_more_toggle_click`,
  UPDATE_BILLING_INITIALIZED = `billing_update_initialized`,
  UPDATE_BILLING_SUCCESS = `billing_update_success`,
  UPDATE_BILLING_ERROR = `billing_update_error`,
  UPDATE_COMPLETE = `billing_update_complete`,
  CONTINUE_MONTHLY = `billing_continue_monthly`,
  CONTINUE_YEARLY_T3_PLUS = `billing_continue_yearly_t3_plus_continue`,
}

const formatCurrency = (amount: number, precision) => {
  return currency(amount).format({ precision });
};

export enum BillingTypes {
  ACH = 'ACH',
  CREDIT_CARD = 'CARD',
}

export default {
  name: 'BillingCycleSelectionPage',
  components: {
    BaseButton,
    BaseIcon,
    LayoutApplicationStyle,
    TypeBody,
    TypeDisplay,
    PricePlanSelector,
  },

  setup(_, { emit }) {
    const { state: registrationState, updateRegistration } = useRegistration();
    const {
      setSelectedPlanByBillingInterval,
      selectedPlan,
      setSelectedPlanById,
      availablePlansByProduct,
      isReady,
      isT3Plus,
    } = usePricePlans();

    const billingPeriod = computed(() => {
      switch (selectedPlan.value?.billingPeriod) {
        case BillingPeriod.MONTH:
          return `Monthly`;
        case BillingPeriod.QUARTER:
          return `Quarterly`;
        case BillingPeriod.HALF_YEAR:
          return `Semi-Annually`;
        case BillingPeriod.YEAR:
          return `Annually`;
        case BillingPeriod.TWO_YEARS:
          return `Biennially`;
        case BillingPeriod.THREE_YEARS:
          return `Triennially`;
        default:
          return '';
      }
    });

    // Kinda hacky
    registrationState.plan && setSelectedPlanById(registrationState.plan);

    const { trialDuration } = useTrials();
    const updatingBilling = ref(false);
    const selectedBillingPeriod: Ref<BillingPeriod | null> = ref(null);
    const selectedBillingType = ref(BillingTypes.CREDIT_CARD);
    const router = useRouter();

    // On initial load,
    // If the customer is in the set group and hasn't changed it on the next page already (and clicked back),
    // Set the billing period to YEAR
    watch(
      selectedPlan,
      (plan) => {
        if (plan?.billingPeriod && !selectedBillingPeriod.value) {
          selectedBillingPeriod.value = plan.billingPeriod as BillingPeriod;
        }
      },
      { immediate: true }
    );

    const selectedBillingPeriodIsMonthly = computed(() => {
      return selectedBillingPeriod.value === BillingPeriod.MONTH;
    });

    watch(
      selectedBillingType,
      (type) => {
        updateRegistration({
          [RegistrationStateNames.PAYMENT_METHOD]: type,
        });
      },
      {
        immediate: true,
      }
    );

    const trackEvent = (eventName: TrackEvents, additionalData = {}) => {
      Analytics.track(eventName, {
        registrationState,
        ...additionalData,
      });
    };

    const purchasedPlanId = computed(() => {
      return registrationState[RegistrationStateNames.PURCHASED_PLAN_ID];
    });

    const monthlyPlan = computed(() =>
      availablePlansByProduct.value.find(
        (plan) => plan.billingPeriod === BillingPeriod.MONTH
      )
    );

    const getPricingConfig = (
      billingPeriod: BillingPeriod,
      discount = 0,
      months = 0,
      extraMonths = 0
    ) => {
      const thisPlan = availablePlansByProduct.value.find(
        (plan) => plan.billingPeriod === billingPeriod
      );

      if (!thisPlan) {
        return;
      }

      const specialMonthly =
        thisPlan.monthlyPrice * (months / (months + extraMonths));

      return {
        total: formatCurrency(thisPlan.price, 0),
        monthly: formatCurrency(thisPlan.monthlyPrice, 0),
        specialMonthly: formatCurrency(specialMonthly, 0),
        discount, // %
      };
    };

    const threeYearPricing = computed(() => {
      return getPricingConfig(BillingPeriod.THREE_YEARS, 25, 36, 3);
    });

    const twoYearPricing = computed(() => {
      return getPricingConfig(BillingPeriod.TWO_YEARS, 20, 36, 3);
    });

    const oneYearPricing = computed(() => {
      return getPricingConfig(BillingPeriod.YEAR, 15);
    });

    const sixMonthPricing = computed(() => {
      return getPricingConfig(BillingPeriod.HALF_YEAR, 12);
    });

    const threeMonthPricing = computed(() => {
      return getPricingConfig(BillingPeriod.QUARTER, 5);
    });

    const monthlyPricing = computed(() => {
      return getPricingConfig(BillingPeriod.MONTH, 0);
    });

    const billingOptions = computed(() => {
      const options = [
        {
          id: 'threeYear',
          key: BillingPeriod.THREE_YEARS,
          features: [`Save ${threeYearPricing.value?.discount}% annually`],
          planTag: `Biggest Savings`,
          planTagType: `primary`,
          html: `<b>After your ${
            trialDuration.value
          }-day trial, your subscription is <span class="line-through italic text-gray-3">${formatCurrency(
            monthlyPlan.value?.price ?? 0,
            0
          )}</span>  ${
            threeYearPricing.value?.specialMonthly
          }/mo, with 3 free months added to your plan.</b> This plan is ideal for elevating HR at your company and providing seamless support for your team. Enjoy Premier Access with expedited onboarding, a dedicated account manager, and extended support to address your most pressing HR needs and guide your strategy.`,
          expandableList: [
            `<b>Concierge onboarding and implementation:</b> Enjoy concierge onboarding and implementation with an assigned account manager & customer experience agent to seamlessly integrate Bambee into your company. They'll assist with onboarding key stakeholders, such as managers or administrative staff. For the first 3 months, you'll receive priority resolution for all HR issues, ensuring you skip the line and your concerns are addressed first as you establish HR in your organization.`,
            `<b>Premier Account Manager:</b> While Bambee provides exceptional customer support, we know the value of having a dedicated point of contact. Your account manager will assist with HR strategy, prioritize your needs, and ensure you receive expedited support for maximum value.`,
            `<b>An incredible savings opportunity:</b> This option not only delivers more value but also offers ${threeYearPricing.value?.discount}% off your annual subscription and 3 months of free service automatically added to your account.`,
          ],
        },
        {
          id: 'twoYear',
          key: BillingPeriod.TWO_YEARS,
          features: [`Save ${twoYearPricing.value?.discount}% annually`],
          planTag: `Best Value`,
          planTagType: `primary`,
          html: `<b>After your ${
            trialDuration.value
          }-day trial, your subscription is <span class="line-through italic text-gray-3">${formatCurrency(
            monthlyPlan.value?.price ?? 0,
            0
          )}</span>  ${
            twoYearPricing.value?.specialMonthly
          }/mo, with 3 free months added to your plan.</b> Ideal for established companies committed to long-term HR strategies and aligning their organization for sustainable growth. This plan includes Premier Access with dedicated onboarding, implementation, and a personal account manager for priority resolution.`,
          expandableList: [
            `<b>Concierge onboarding and implementation:</b> Enjoy concierge onboarding and implementation with an assigned account manager & customer experience agent to seamlessly integrate Bambee into your company. They'll assist with onboarding key stakeholders, such as managers or administrative staff. For the first 3 months, you'll receive priority resolution for all HR issues, ensuring you skip the line and your concerns are addressed first as you establish HR in your organization.`,
            `<b>Premier Account Manager:</b> While Bambee provides exceptional customer support, we know the value of having a dedicated point of contact. Your account manager will assist with HR strategy, prioritize your needs, and ensure you receive expedited support for maximum value.`,
            `<b>An incredible savings opportunity:</b> This option not only delivers more value but also offers ${twoYearPricing.value?.discount}% off your annual subscription and 3 months of free service automatically added to your account.`,
          ],
        },
        {
          id: 'annual',
          key: BillingPeriod.YEAR,
          features: [
            isT3Plus.value
              ? ''
              : `Save ${oneYearPricing.value?.discount}% annually`,
          ],
          planTag: `Most Popular`,
          planTagType: `secondary`,
          html: `<b>After your ${
            trialDuration.value
          }-day trial, your subscription is <span class="line-through italic text-gray-3">${formatCurrency(
            monthlyPlan.value?.price ?? 0,
            0
          )}</span>  ${
            oneYearPricing.value?.monthly
          }/mo.</b> Perfect for companies ready to establish a strong HR foundation with the convenience of annual billing.`,
          expandableList: [],
        },
        ...(isT3Plus.value
          ? []
          : [
              {
                id: 'sixMonth',
                key: BillingPeriod.HALF_YEAR,
                features: [`Save ${sixMonthPricing.value?.discount}% monthly`],
                html: `<b>After your ${
                  trialDuration.value
                }-day trial, your subscription is <span class="line-through italic text-gray-3">${formatCurrency(
                  monthlyPlan.value?.price ?? 0,
                  0
                )}</span>  ${
                  sixMonthPricing.value?.monthly
                }/mo.</b> Ideal for businesses looking to build a strong HR foundation with year-round support and compliance guidance.`,
                expandableList: [],
              },
              {
                id: 'threeMonth',
                key: BillingPeriod.QUARTER,
                features: [
                  `Save ${threeMonthPricing.value?.discount}% monthly`,
                ],
                html: `<b>After your ${
                  trialDuration.value
                }-day trial, your subscription is <span class="line-through italic text-gray-3">${formatCurrency(
                  monthlyPlan.value?.price ?? 0,
                  0
                )}</span>  ${
                  threeMonthPricing.value?.monthly
                }/mo.</b> Perfect for businesses wanting to experience our comprehensive support before a longer commitment.`,
                expandableList: [],
              },
            ]),
      ]
        .filter(Boolean)
        // Removing some info if they're T3+
        .map((option) => ({
          ...option,
          html: isT3Plus.value ? '' : option.html,
          expandableList: isT3Plus.value ? [] : option.expandableList,
        }));

      if (isT3Plus.value) {
        return options.reverse();
      }

      return options;
    });

    const buttonText = computed(() => {
      return updatingBilling.value ? `Updating Billing` : `Continue`;
    });

    const updatePlanAndContinue = async () => {
      updatingBilling.value = true;
      await updatePlan({
        companyId: registrationState[RegistrationStateNames.COMPANY_ID],
        planValue: selectedPlan.value?.value,
      }).then(() => {
        updateRegistration({
          [RegistrationStateNames.BILLING_PERIOD]:
            selectedPlan.value?.billingPeriod,
          [RegistrationStateNames.PLAN_ID]: selectedPlan.value?.value,
        });
        updatingBilling.value = false;

        emit('complete');
      });
    };

    const handleSelectBillingCycle = (billingPeriod: BillingPeriod) => {
      selectedBillingPeriod.value = billingPeriod;
      setSelectedPlanByBillingInterval(billingPeriod);

      updateRegistration({
        [RegistrationStateNames.PLAN_ID]: selectedPlan.value?.value,
        [RegistrationStateNames.BILLING_PERIOD]: billingPeriod,
      });
      saveInLocalStorage(
        LocalStorageKeys.BILLING_PLAN_ID,
        selectedPlan.value?.value || ''
      );
      trackEvent(TrackEvents.BILLING_PERIOD_CLICK, { target: billingPeriod });
    };

    const handleContinueMonthly = () => {
      const monthlyPage = SelfServicePages.find(
        (page) => page.slug === SelfServicePageSlugs.MONTHLY_CONFIRMATION
      );
      setSelectedPlanByBillingInterval(BillingPeriod.MONTH);

      updateRegistration({
        [RegistrationStateNames.PLAN_ID]: selectedPlan.value?.value,
        [RegistrationStateNames.BILLING_PERIOD]: BillingPeriod.MONTH,
      });
      saveInLocalStorage(
        LocalStorageKeys.BILLING_PLAN_ID,
        selectedPlan.value?.value || ''
      );

      const monthlyConfirmationIndex = SelfServicePages.findIndex(
        (page) => page.slug === monthlyPage?.slug
      );
      trackEvent(TrackEvents.CONTINUE_MONTHLY, {
        target: BillingPeriod.MONTH,
      });

      if (isT3Plus.value) {
        emit('complete');
      } else {
        goToPage(SelfServicePages[monthlyConfirmationIndex], router);
      }
    };

    const handleBillingFinalization = async () => {
      if (selectedBillingPeriod.value === BillingPeriod.MONTH) {
        if (isT3Plus.value) {
          trackEvent(TrackEvents.CONTINUE_YEARLY_T3_PLUS, {
            target: selectedBillingPeriod.value,
          });
        }

        return handleContinueMonthly();
      }

      if (
        selectedBillingPeriod.value !==
        registrationState[RegistrationStateNames.BILLING_PERIOD]
      ) {
        trackEvent(TrackEvents.UPDATE_BILLING_INITIALIZED, {
          target: selectedBillingPeriod.value,
        });
        updatingBilling.value = true;

        try {
          updateRegistration({
            [RegistrationStateNames.PLAN_ID]: selectedPlan.value?.value,
          });
          trackEvent(TrackEvents.UPDATE_BILLING_SUCCESS, {
            target: selectedBillingPeriod.value,
            plan: selectedPlan.value?.value,
          });
          updatePlanAndContinue();

          return;
        } catch (error) {
          console.error(error);
          trackEvent(TrackEvents.UPDATE_BILLING_ERROR);
        } finally {
          updatingBilling.value = false;
        }
      }
      trackEvent(TrackEvents.UPDATE_COMPLETE);
      if (isT3Plus.value) {
        updatePlanAndContinue();
      } else {
        const ACHPage = SelfServicePages.find(
          (page) => page.slug === SelfServicePageSlugs.ACH_CONFIRMATION
        );

        const ACHConfirmationPage = SelfServicePages.findIndex(
          (page) => page.slug === ACHPage?.slug
        );
        goToPage(SelfServicePages[ACHConfirmationPage], router);
      }
    };

    const handleBillingLearnMoreToggle = (billingType) => {
      trackEvent(TrackEvents.LEARN_MORE_CLICK, { billingType });
    };

    watch(selectedBillingPeriod, (newPeriod, oldPeriod) => {
      if (newPeriod && newPeriod !== oldPeriod) {
        handleSelectBillingCycle(newPeriod);
      }
    });

    return {
      billingOptions,
      selectedBillingPeriod,
      buttonText,
      purchasedPlanId,
      updatingBilling,
      handleSelectBillingCycle,
      handleBillingLearnMoreToggle,
      handleBillingFinalization,
      selectedPlan,
      selectedBillingType,
      handleContinueMonthly,
      isReady,
      isT3Plus,
      selectedBillingPeriodIsMonthly,
      billingPeriod,
    };
  },
};
